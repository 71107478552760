<script>
import Layout from '@layouts/main';
import ContentLoading from '@components/content-loading';
import DriverHeader from '@components/drivers/header';
import AddressEdit from '@components/address-edit';
import GarageAddressEdit from '@components/garage-address-edit';
import {
  enumStatus,
  signupFlowStatus,
  work_cities
} from '@utils/driver';
import {
  DriverEntity,
  status as driverStatus
} from '@entities/driver';
import DRIVER_GET from '@graphql/driver/queries/get.gql';
import GET_PLANS from '@graphql/plan/queries/get.gql';
import PRE_OPEN_BOOKING from '@graphql/booking/mutations/pre-open-booking.gql';
import {
  class2obj
} from '@utils/class2obj';
import {
  hooks as leadHooks,
  LeadEntity
} from '@entities/lead';
import EventBus from '@utils/eventBus';

export default {
  name: 'LeadCompleteRegister',
  page: {
    title: 'Motoristas',
  },
  components: {
    Layout,
    ContentLoading,
    DriverHeader,
    AddressEdit,
    GarageAddressEdit,
  },
  data: () => {
    return {
      driverModule: null,
      data: {},
      plans: [],
      changedStatus: false,
      booking: false,
      updatedDriver: false,
      form_status_options: enumStatus,
      pending_docs: false,
      signup_flow_status: signupFlowStatus,
      work_cities: work_cities,
      selectedPlan: '',
      block_ui: false,
      fieldState: null,
    };
  },
  apollo: {
    plans: {
      query: GET_PLANS,
      variables() {
        return {
          status: 'ACTIVE'
        };
      },
      result({
        data
      }) {
        if (!data) return;
        this.plans = data.plans.items.map(plan => ({
          text: `${plan.name} | ${plan.transaction_amount / 100}`,
          value: plan.id,
        }));
      },
    },
  },
  computed: {
    /**
     * lead listener
     * @returns {LeadEntity | null}
     */
    driver() {
      const driver = this.$store.getters['driver/driver'];
      if (driver instanceof DriverEntity)
        return LeadEntity.of(driver.toObject());

      return null;
    },

    validPlan() {
      return this.driver.status === driverStatus.PENDING_DOCS ? !!this.selectedPlan : null;
    },
    validFields() {
      if (!this.driver || !(this.driver instanceof DriverEntity || this.driver instanceof LeadEntity)) return false;

      return this.driver.isPendingDocs() ?
        this.driver.isValidEmail() &&
        this.validPlan &&
        this.driver.isValidCNH() &&
        this.driver.isValidCpf() &&
        this.driver.hasEmergencyData() &&
        this.driver.phone_number :
        true;
    },
  },
  created() {
    this.$store.dispatch('driver/byGql', {
      gql: DRIVER_GET,
      variables: {
        id: this.$route.params.id,
      },
    });
  },
  mounted() {
    EventBus.$on(leadHooks.updated, () => this.updatedDriver = true);
  },
  methods: {
    updateAddressModel(_item, _data) {
      this.driver['address_' + _item] = _data;
    },
    updateGarageAddressModel(_item, _data) {
      this.driver.garage_address[_item] = _data;
    },
    async updateLeadSituation() {
      this.driver.address_street_number = this.driver.address_street_no_number ?
        'N/A' :
        this.driver.address_street_number;

      this.driver.garage_address = this.driver.garage_other_address ? this.driver.garage_address : null;
      await this.driver.update();
    },
    async findOrCreateBooking() {
      await this.$apollo.mutate({
        mutation: PRE_OPEN_BOOKING,
        variables: {
          driver: this.driver.id,
          plan: this.selectedPlan,
          started_at: new Date().toJSON().slice(0, 10).replace('T', ' '),
        },
      }).then(res => {
        this.booking = true;
        return res;
      }).catch(() => {
        return false;
      });
    },
    updateDriver() {
      this.block_ui = true;
      const original_data = class2obj(this.driver);
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: 'Tem certeza?',
        text: 'Você irá alterar os dados deste motorista',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(async result => {
        if (result.value) {
          try {
            if (this.driver.isPendingDocs() && !this.driver.address_postal_code) {
              throw new Error('Preencha todos os campos obrigatórios');
            }
            if (this.driver.isPendingDocs()) {
              if (!this.selectedPlan) throw new Error('Selecione um plano');
              await this.findOrCreateBooking();
              if (!this.booking) throw new Error('Erro ao abrir booking');
            }
            await this.updateLeadSituation();

            this.changedStatus = this.driver.isPendingDocs() ?
              this.updatedDriver && this.booking :
              this.updatedDriver;

            if (this.changedStatus) {
              confirmationWindow({
                type: 'success',
                title: 'Alteração realizada',
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.$router.push({
                  path: '/drivers/profile/' + this.driver.id
                });
              });
            } else {
              throw new Error('Não foi possível atualizar');
            }
          } catch (err) {
            this.$swal({
              type: 'error',
              title: `Ocorreu um erro ${err}`,
              showConfirmButton: false,
              timer: 4000,
            }).then(() => {
              this.$store.dispatch('driver/clearDriversList');
              this.$router.push({
                path: '/drivers/profile/' + this.data.id
              });
            });
          }
        } else {
          this.block_ui = false;
          this.data = original_data;
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <driver-header :id="$route.params.id" />
    <div v-if="driver" class="animated fadeIn">
      <b-card>
        <b-row slot="header">
          <b-col cols="8">
            <h4 class="mt-2">
              Completar cadastro
            </h4>
          </b-col>
          <b-col cols="4" class="text-right mt-1">
            <b-button :disabled="block_ui || !validFields" class="mr-2" variant="success"
                      @click="updateDriver"
            >
              Salvar
            </b-button>
            <b-button :disabled="block_ui" :to="'/drivers/profile/' + $route.params.id" variant="danger">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
        <b-form>
          <b-form-row>
            <b-form-group label="CPF *" class="col-md-3 col-sm-12" label-for="driver-cpf">
              <b-form-input id="driver-cpf" type="text" maxlength="11"
                            class="form-control" :state="driver.isValidCpf()" :value="driver.cpf"
                            :disabled="block_ui" @input="cpf => $store.commit('driver/updateDriverEntity', {key: 'cpf', value: cpf})"
              />
              <div class="invalid-feedback">
                Digite um CPF válido
              </div>
            </b-form-group>
            <b-form-group label="Nome do motorista *" label-for="driver-name" class="col-md-4 col-sm-12">
              <b-form-input id="driver-name" :disabled="block_ui" type="text"
                            :state="driver.isPendingDocs() ? !!driver.name : null" :value="driver.name" required
                            @input="name => $store.commit('driver/updateDriverEntity', {key: name, value: name})"
              />
              <div class="invalid-feedback">
                O nome é obrigatório
              </div>
            </b-form-group>
            <b-form-group label="Telefone *" label-for="driver-phone-number" class="col-md-2 col-sm-10">
              <the-mask id="driver-phone-number" type="tel" class="form-control"
                        :disabled="block_ui" :value="driver.phone_number" :mask="['+## ## ##### ####', '+## ## #### ####']"
                        @input="phone_number => $store.commit('driver/updateDriverEntity', {key: 'phone_number', value: phone_number})"
              />
            </b-form-group>
            <b-form-group label="Alterar status para" label-for="signup-status" class="col-md-3 col-sm-12">
              <b-form-select id="signup-status" :disabled="block_ui" :value="driver.status"
                             :options="signup_flow_status" @change="status => $store.commit('driver/updateDriverEntity', {key: 'status', value: status})"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <b-form-group label="Data nascimento" label-for="driver-birthdate" class="col-md-3 col-sm-12">
              <b-form-input id="driver-birthdate" :disabled="block_ui" type="date"
                            :value="driver.birthdate" @input="birthdate => $store.commit('driver/updateDriverEntity', {key: 'birthdate', value: birthdate})"
              />
            </b-form-group>
            <b-form-group label="Email *" label-for="driver-email" class="col-md-4 col-sm-12">
              <b-form-input id="driver-email" :disabled="block_ui" autocomplete="email"
                            type="email" :state="driver.isValidEmail()" :value="driver.email"
                            @input="email => $store.commit('driver/updateDriverEntity', {key: 'email', value: email})"
              />
              <div class="invalid-feedback">
                Digite um email válido
              </div>
            </b-form-group>
            <b-form-group label="&nbsp;" label-for="driver-email-verified" class="col-md-5 col-sm-12">
              <b-form-checkbox :disabled="block_ui" :value="driver.unsubscribed" class="mt-1"
                               @change="unsubscribed => $store.commit('driver/updateDriverEntity', {key: 'unsubscribed', value: unsubscribed})"
              >
                Não receber comunicação de marketing
              </b-form-checkbox>
            </b-form-group>
          </b-form-row>
          <b-form-row />
          <hr>
          <b-form-row>
            <b-form-group label="Nome emergência 1 *" label-for="driver-emergency-1-name" class="col-md-3 col-sm-12">
              <b-form-input id="driver-emergency-1-name" :disabled="block_ui" type="text"
                            :state="driver.hasEmergencyData()" :value="driver.emergency_1_name"
                            @input="emergency_1_name => $store.commit('driver/updateDriverEntity', {key: 'emergency_1_name', value: emergency_1_name})"
              />
              <div class="invalid-feedback">
                Nome e número de são obrigatórios
              </div>
            </b-form-group>
            <b-form-group label="Telefone emergência 1 *" label-for="driver-emergency-1-phone-number" class="col-md-3 col-sm-10">
              <the-mask id="driver-emergency-1-phone-number" type="tel" class="form-control"
                        :disabled="block_ui" :mask="['+## ## ##### ####', '+## ## #### ####']" :value="driver.emergency_1_phone_number"
                        @input="emergency_1_phone_number => $store.commit('driver/updateDriverEntity', {key: 'emergency_1_phone_number', value: emergency_1_phone_number})"
              />
            </b-form-group>
            <b-form-group label="Nome emergência 2" label-for="driver-emergency-2-name" class="col-md-3 col-sm-12">
              <b-form-input id="driver-emergency-2-name" :disabled="block_ui" type="text"
                            :state="fieldState" :value="driver.emergency_2_name"
                            @input="emergency_2_name => $store.commit('driver/updateDriverEntity', {key: 'emergency_2_name', value: emergency_2_name})"
              />
            </b-form-group>
            <b-form-group label="Telefone emergência 2" label-for="driver-emergency-2-phone-number" class="col-md-3 col-sm-10">
              <the-mask id="driver-emergency-2-phone-number" type="tel" class="form-control"
                        :disabled="block_ui" :mask="['+## ## ##### ####', '+## ## #### ####']" :value="driver.emergency_2_phone_number"
                        @input="emergency_2_phone_number => $store.commit('driver/updateDriverEntity', {key: 'emergency_2_phone_number', value: emergency_2_phone_number})"
              />
            </b-form-group>
          </b-form-row>
          <hr>
          <b-form-row>
            <b-form-group label="CNH *" label-for="driver-license-number" class="col-md-2 col-sm-12">
              <b-form-input id="driver-license-number" type="tel" maxlength="11"
                            :state="driver.isValidCNH()" :value="driver.license_number"
                            @input="license_number => $store.commit('driver/updateDriverEntity', {key: 'license_number', value: license_number})"
              />
              <div class="invalid-feedback">
                A CNH precisa de 11 dígitos
              </div>
            </b-form-group>
            <b-form-group label="Cidade de atuação *" label-for="work-city" class="col-md-4 col-sm-12">
              <b-form-select id="working-city" :disabled="block_ui" :value="driver.work_city"
                             :state="driver.isPendingDocs() ? !!driver.work_city : null"
                             :options="work_cities" @input="work_city => $store.commit('driver/updateDriverEntity', {key: 'work_city', value: work_city})"
              />
            </b-form-group>
            <b-form-group label="Plano" label-for="plans" class="col-md-5 col-sm-12">
              <b-form-select id="plans" v-model="selectedPlan" :disabled="block_ui || !driver.isPendingDocs()"
                             :options="plans" :state="validPlan"
              />
              <div class="invalid-feedback">
                Selecione um plano
              </div>
            </b-form-group>
          </b-form-row>
          <hr>
          <b-col cols="8" class="pl-0">
            <h5 class="mt-1 mb-2 ml-0">
              Endereço Residencial
            </h5>
          </b-col>
          <address-edit :pending-docs="driver.isPendingDocs() || null" :street_name="driver.address_street_name || ''" :street_number="driver.address_street_number || ''"
                        :street_no_number="driver.address_street_no_number || false"
                        :street_details="driver.address_street_details || ''" :neighborhood="driver.address_neighborhood || ''" :city="driver.address_city || ''"
                        :state="driver.address_state || ''" :postal_code="driver.address_postal_code || ''"
                        :country="driver.address_country || ''" :block_ui="block_ui" @update-form-data="updateAddressModel"
          />
          <b-form-checkbox v-model="driver.garage_other_address" :disabled="block_ui" class="mt-1">
            Garagem em outro
            endereço?
          </b-form-checkbox>
          <garage-address-edit v-if="driver.garage_other_address" :garage_other_address="driver.garage_other_address || ''" :street_name="driver.garage_address.street_name || ''"
                               :street_number="driver.garage_address.street_number || ''"
                               :street_details="driver.garage_address.street_details || ''" :neighborhood="driver.garage_address.neighborhood || ' '" :city="driver.garage_address.city || ' '"
                               :state="driver.garage_address.state || ' '"
                               :postal_code="driver.garage_address.postal_code || ' '" :block_ui="block_ui" @update-garage-form-data="this.updateGarageAddressModel"
          />
        </b-form>
        <b-row slot="footer">
          <b-col class="text-right">
            <b-button :disabled="block_ui || !validFields" class="mr-2" variant="success"
                      @click="updateDriver"
            >
              Salvar
            </b-button>
            <b-button :disabled="block_ui" :to="'/drivers/profile/' + $route.params.id" variant="danger">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
#driver-license-expire-date.form-control,
#driver-license-issue-date.form-control {
    padding-right: 0 !important;
}
</style>
