import { DriverEntity } from './driver';
import apolloClient from '@utils/graphql';
import LEAD_UPDATE from '@graphql/driver/mutations/update-lead.gql';
import EventBus from '@utils/eventBus';

export const hooks = {
  updated: 'LEAD_UPDATED',
};

export class LeadEntity extends DriverEntity {
  constructor(payload = {}) {
    super(payload);
  }

  /**
   *
   * @param object
   * @returns {LeadEntity}
   */
  static of(object = {}) {
    return new LeadEntity(object);
  }

  /**
   *
   * @returns {Promise<LeadEntity | never>}
   */
  update() {
    return apolloClient.mutate({
      mutation: LEAD_UPDATE,
      variables: {
        ...this.toObject(),
      },
    }).then(({ data }) => {
      const { changeLeadSituation } = data;
      if (!changeLeadSituation)
        throw new Error('Lead was returned null');

      let leadEntity = LeadEntity.of(changeLeadSituation);
      EventBus.$emit('LEAD_UPDATED', leadEntity);
      return leadEntity;
    });
  }
}
