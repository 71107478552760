<script>
import { AddressStatesList, AddressStatesListMexico } from '@utils/address';
import { API } from 'aws-amplify';
import { isMexico, isBrazil } from '@utils/helper-region'

  export default {
    name: 'GarageAddressEdit',
    props: {
      street_name: {
        type: String,
        required: true,
        default: '',
      },
      street_number: {
        type: String,
        required: false,
        default: '',
      },
      garage_other_address: {
        type: Boolean,
        required: true,
        default: false,
      },
      street_details: {
        type: String,
        required: false,
        default: '',
      },
      neighborhood: {
        type: String,
        required: false,
        default: '',
      },
      city: {
        type: String,
        required: true,
        default: '',
      },
      state: {
        type: String,
        required: true,
        default: '',
      },
      postal_code: {
        type: String,
        required: true,
        default: '',
      },
      block_ui: {
        type: Boolean,
        default: false,
      },
    },
    data: () => {
      return {
        data: {
          street_name: '',
          street_number: '',
          street_details: '',
          neighborhood: '',
          city: '',
          state: '',
          postal_code: '',
        },
        readonly: false,
        loading: false,
        form_states_options: [],
        form_country_options: [],
        maskLetterNumberBarUpper: {
          F: {
            pattern: /[a-zA-Z0-9/]/,
            transform: v => v.toLocaleUpperCase(),
          },
        },
      };
    },
    watch: {
      postal_code: function(val) {
        this.getAddressByPostalCode(val);
      },
    },
    mounted() {
      if (!this.garage_other_address) return;
      this.data.street_name = this.street_name;
      this.data.street_number = this.street_number;
      this.data.street_details = this.street_details;
      this.data.neighborhood = this.neighborhood;
      this.data.city = this.city;
      this.data.state = this.state;
      this.data.postal_code = this.postal_code;
      this.data.readonly = this.readonly;
      this.data.block_ui = this.block_ui;

      if (isBrazil()) {
        this.form_states_options = AddressStatesList
        this.form_country_options = [{text: 'Brasil', value: 'BR'}]
      } else if (isMexico()) {
        this.form_country_options= [{text: 'México', value: 'MX'}]
        this.form_states_options = AddressStatesListMexico
      }

      this.getAddressByPostalCode();
    },
    methods: {
      checkNoNumber($checked) {
        if ($checked) {
          this.data.street_number = 'N/A';
        } else {
          this.data.street_number = '';
        }
      },
      updateGarageAddressModel($target, $event) {
        this.$emit('update-garage-form-data', $target, $event);
      },
      getAddressByPostalCode() {
        if (this.data.postal_code.length === 8) {
          this.loading = true;
          const _ = this
          API.get('API', '/utils/postalcode/' + this.data.postal_code, {
            response: true,
          }).then(res => {
            if (!res.data.state) {
              this.readonly = false;
              this.data.street_name = '';
              this.data.street_number = '';
              this.data.street_details = '';
              this.data.neighborhood = '';
              this.data.city = '';
              this.data.state = '';
            } else {
              const value_results = [
                res.data.street_name,
                res.data.neighborhood,
                res.data.city,
                res.data.state,
              ].filter(item => !item);

              this.readonly = !value_results.length;

              this.data.street_name = res.data.street_name || '';
              this.data.street_number = this.street_number || '';
              this.data.street_details = this.street_details || res.data.street_details || '';
              this.data.neighborhood = res.data.neighborhood || '';
              this.data.city = res.data.city || '';
              this.data.state = res.data.state || '';
            }

            _.$emit('update-garage-full-form-data', this.data);
            this.loading = false;
          }).catch(() => {
            this.loading = false;
            this.readonly = false;
          });
        }
      },
    },
  };
</script>
<template>
  <div>
    <hr>
    <h5 class="pt-1 pb-2">
      {{ $t('drivers.labels.garageAddress') }}
    </h5>
    <b-form-row>
      <!-- <b-form-group
        id="fg-driver-address-country"
        label="País"
        label-for="driver-address-country"
        class="col-md-2 col-sm-12"
      >
      <b-form-select :disabled="block_ui || loading" :value="data.country" :options="form_country_options"/>
      </b-form-group> -->
      <b-form-group
        id="fg-driver-garage-postal-code"
        :label="$t('drivers.labels.cep')"
        label-for="driver-garage-postal-code"
        class="col-md-2 col-sm-12"
      >
        <the-mask
          id="driver-garage-postal-code"
          v-model="data.postal_code"
          type="text"
          class="form-control"
          :disabled="block_ui || loading"
          :mask="$t('drivers.masks.postalCode')"
          @input="updateGarageAddressModel('postal_code', $event)"
        />
      </b-form-group>

      <b-form-group
        id="fg-driver-garage-street"
        :label="$t('drivers.labels.garageAddress')"
        label-for="driver-garage-street"
        class="col-md-5 col-sm-12"
      >
        <b-form-input
          id="driver-garage-street"
          v-model="data.street_name"
          :disabled="block_ui || loading"
          :readonly="readonly"
          type="text"
          @input="updateGarageAddressModel('street_name', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-garage-street-number"
        :label="$t('drivers.labels.number')"
        label-for="driver-garage-street-number"
        class="col-md-1 col-sm-12"
      >
        <the-mask
          id="driver-garage-street-number"
          v-model="data.street_number"
          type="text"
          class="form-control"
          mask="FFFFFF"
          :tokens="maskLetterNumberBarUpper"
          @input="updateGarageAddressModel('street_number', $event)"
        />
      </b-form-group>
      <!-- <b-form-group
        id="fg-driver-address-no-number"
        label="&nbsp;"
        label-for="driver-address-no-number"
        class="col-md-2 col-sm-12"
      >
        <b-form-checkbox
          :disabled="block_ui || loading"
          v-model="data.street_no_number"
          v-on:change="checkNoNumber"
          v-on:input="updateGarageAddressModel('street_no_number', $event)"
          class="mt-1">Sem número
        </b-form-checkbox>
      </b-form-group> -->
    </b-form-row>
    <b-form-row>
      <b-form-group
        id="fg-driver-garage-street-details"
        :label="$t('drivers.labels.complement')"
        label-for="driver-garage-street-details"
        class="col-md-2 col-sm-12"
      >
        <b-form-input
          id="driver-garage-street-details"
          v-model="data.street_details"
          :disabled="block_ui || loading"
          type="text"
          @input="updateGarageAddressModel('street_details', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-address-neighborhood"
        :label="$t('drivers.labels.district')"
        label-for="driver-address-neighborhood"
        class="col-md-3 col-sm-12"
      >
        <b-form-input
          id="driver-address-neighborhood"
          v-model="data.neighborhood"
          :disabled="block_ui || loading"
          :readonly="readonly"
          type="text"
          @input="updateGarageAddressModel('neighborhood', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-address-city"
        :label="$t('drivers.labels.city')"
        label-for="driver-address-city"
        class="col-md-4 col-sm-12"
      >
        <b-form-input
          id="driver-address-city"
          v-model="data.city"
          :disabled="block_ui || loading"
          :readonly="readonly"
          type="text"
          @input="updateGarageAddressModel('city', $event)"
        />
      </b-form-group>
      <b-form-group
        id="fg-driver-address-state"
        :label="$t('drivers.labels.state')"
        label-for="driver-address-state"
        class="col-md-3 col-sm-12"
      >
        <b-form-select
          v-model="data.state"
          :disabled="block_ui || loading"
          :readonly="readonly"
          :options="form_states_options"
          @input="updateGarageAddressModel('state', $event)"
        />
      </b-form-group>
    </b-form-row>
  </div>
</template>
