var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("driver-header", { attrs: { id: _vm.$route.params.id } }),
      _vm.driver
        ? _c(
            "div",
            { staticClass: "animated fadeIn" },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { attrs: { slot: "header" }, slot: "header" },
                    [
                      _c("b-col", { attrs: { cols: "8" } }, [
                        _c("h4", { staticClass: "mt-2" }, [
                          _vm._v(" Completar cadastro "),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right mt-1",
                          attrs: { cols: "4" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                disabled: _vm.block_ui || !_vm.validFields,
                                variant: "success",
                              },
                              on: { click: _vm.updateDriver },
                            },
                            [_vm._v(" Salvar ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.block_ui,
                                to: "/drivers/profile/" + _vm.$route.params.id,
                                variant: "danger",
                              },
                            },
                            [_vm._v(" Cancelar ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "CPF *",
                                "label-for": "driver-cpf",
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "driver-cpf",
                                  type: "text",
                                  maxlength: "11",
                                  state: _vm.driver.isValidCpf(),
                                  value: _vm.driver.cpf,
                                  disabled: _vm.block_ui,
                                },
                                on: {
                                  input: (cpf) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      { key: "cpf", value: cpf }
                                    ),
                                },
                              }),
                              _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Digite um CPF válido "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-4 col-sm-12",
                              attrs: {
                                label: "Nome do motorista *",
                                "label-for": "driver-name",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "driver-name",
                                  disabled: _vm.block_ui,
                                  type: "text",
                                  state: _vm.driver.isPendingDocs()
                                    ? !!_vm.driver.name
                                    : null,
                                  value: _vm.driver.name,
                                  required: "",
                                },
                                on: {
                                  input: (name) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      { key: name, value: name }
                                    ),
                                },
                              }),
                              _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" O nome é obrigatório "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-10",
                              attrs: {
                                label: "Telefone *",
                                "label-for": "driver-phone-number",
                              },
                            },
                            [
                              _c("the-mask", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "driver-phone-number",
                                  type: "tel",
                                  disabled: _vm.block_ui,
                                  value: _vm.driver.phone_number,
                                  mask: [
                                    "+## ## ##### ####",
                                    "+## ## #### ####",
                                  ],
                                },
                                on: {
                                  input: (phone_number) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      {
                                        key: "phone_number",
                                        value: phone_number,
                                      }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "Alterar status para",
                                "label-for": "signup-status",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "signup-status",
                                  disabled: _vm.block_ui,
                                  value: _vm.driver.status,
                                  options: _vm.signup_flow_status,
                                },
                                on: {
                                  change: (status) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      { key: "status", value: status }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "Data nascimento",
                                "label-for": "driver-birthdate",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "driver-birthdate",
                                  disabled: _vm.block_ui,
                                  type: "date",
                                  value: _vm.driver.birthdate,
                                },
                                on: {
                                  input: (birthdate) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      { key: "birthdate", value: birthdate }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-4 col-sm-12",
                              attrs: {
                                label: "Email *",
                                "label-for": "driver-email",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "driver-email",
                                  disabled: _vm.block_ui,
                                  autocomplete: "email",
                                  type: "email",
                                  state: _vm.driver.isValidEmail(),
                                  value: _vm.driver.email,
                                },
                                on: {
                                  input: (email) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      { key: "email", value: email }
                                    ),
                                },
                              }),
                              _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Digite um email válido "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-5 col-sm-12",
                              attrs: {
                                label: "&nbsp;",
                                "label-for": "driver-email-verified",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    disabled: _vm.block_ui,
                                    value: _vm.driver.unsubscribed,
                                  },
                                  on: {
                                    change: (unsubscribed) =>
                                      _vm.$store.commit(
                                        "driver/updateDriverEntity",
                                        {
                                          key: "unsubscribed",
                                          value: unsubscribed,
                                        }
                                      ),
                                  },
                                },
                                [
                                  _vm._v(
                                    " Não receber comunicação de marketing "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-form-row"),
                      _c("hr"),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "Nome emergência 1 *",
                                "label-for": "driver-emergency-1-name",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "driver-emergency-1-name",
                                  disabled: _vm.block_ui,
                                  type: "text",
                                  state: _vm.driver.hasEmergencyData(),
                                  value: _vm.driver.emergency_1_name,
                                },
                                on: {
                                  input: (emergency_1_name) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      {
                                        key: "emergency_1_name",
                                        value: emergency_1_name,
                                      }
                                    ),
                                },
                              }),
                              _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Nome e número de são obrigatórios "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-10",
                              attrs: {
                                label: "Telefone emergência 1 *",
                                "label-for": "driver-emergency-1-phone-number",
                              },
                            },
                            [
                              _c("the-mask", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "driver-emergency-1-phone-number",
                                  type: "tel",
                                  disabled: _vm.block_ui,
                                  mask: [
                                    "+## ## ##### ####",
                                    "+## ## #### ####",
                                  ],
                                  value: _vm.driver.emergency_1_phone_number,
                                },
                                on: {
                                  input: (emergency_1_phone_number) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      {
                                        key: "emergency_1_phone_number",
                                        value: emergency_1_phone_number,
                                      }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                label: "Nome emergência 2",
                                "label-for": "driver-emergency-2-name",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "driver-emergency-2-name",
                                  disabled: _vm.block_ui,
                                  type: "text",
                                  state: _vm.fieldState,
                                  value: _vm.driver.emergency_2_name,
                                },
                                on: {
                                  input: (emergency_2_name) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      {
                                        key: "emergency_2_name",
                                        value: emergency_2_name,
                                      }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-10",
                              attrs: {
                                label: "Telefone emergência 2",
                                "label-for": "driver-emergency-2-phone-number",
                              },
                            },
                            [
                              _c("the-mask", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "driver-emergency-2-phone-number",
                                  type: "tel",
                                  disabled: _vm.block_ui,
                                  mask: [
                                    "+## ## ##### ####",
                                    "+## ## #### ####",
                                  ],
                                  value: _vm.driver.emergency_2_phone_number,
                                },
                                on: {
                                  input: (emergency_2_phone_number) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      {
                                        key: "emergency_2_phone_number",
                                        value: emergency_2_phone_number,
                                      }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                label: "CNH *",
                                "label-for": "driver-license-number",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "driver-license-number",
                                  type: "tel",
                                  maxlength: "11",
                                  state: _vm.driver.isValidCNH(),
                                  value: _vm.driver.license_number,
                                },
                                on: {
                                  input: (license_number) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      {
                                        key: "license_number",
                                        value: license_number,
                                      }
                                    ),
                                },
                              }),
                              _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" A CNH precisa de 11 dígitos "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-4 col-sm-12",
                              attrs: {
                                label: "Cidade de atuação *",
                                "label-for": "work-city",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "working-city",
                                  disabled: _vm.block_ui,
                                  value: _vm.driver.work_city,
                                  state: _vm.driver.isPendingDocs()
                                    ? !!_vm.driver.work_city
                                    : null,
                                  options: _vm.work_cities,
                                },
                                on: {
                                  input: (work_city) =>
                                    _vm.$store.commit(
                                      "driver/updateDriverEntity",
                                      { key: "work_city", value: work_city }
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-5 col-sm-12",
                              attrs: { label: "Plano", "label-for": "plans" },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "plans",
                                  disabled:
                                    _vm.block_ui || !_vm.driver.isPendingDocs(),
                                  options: _vm.plans,
                                  state: _vm.validPlan,
                                },
                                model: {
                                  value: _vm.selectedPlan,
                                  callback: function ($$v) {
                                    _vm.selectedPlan = $$v
                                  },
                                  expression: "selectedPlan",
                                },
                              }),
                              _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(" Selecione um plano "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "b-col",
                        { staticClass: "pl-0", attrs: { cols: "8" } },
                        [
                          _c("h5", { staticClass: "mt-1 mb-2 ml-0" }, [
                            _vm._v(" Endereço Residencial "),
                          ]),
                        ]
                      ),
                      _c("address-edit", {
                        attrs: {
                          "pending-docs": _vm.driver.isPendingDocs() || null,
                          street_name: _vm.driver.address_street_name || "",
                          street_number: _vm.driver.address_street_number || "",
                          street_no_number:
                            _vm.driver.address_street_no_number || false,
                          street_details:
                            _vm.driver.address_street_details || "",
                          neighborhood: _vm.driver.address_neighborhood || "",
                          city: _vm.driver.address_city || "",
                          state: _vm.driver.address_state || "",
                          postal_code: _vm.driver.address_postal_code || "",
                          country: _vm.driver.address_country || "",
                          block_ui: _vm.block_ui,
                        },
                        on: { "update-form-data": _vm.updateAddressModel },
                      }),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mt-1",
                          attrs: { disabled: _vm.block_ui },
                          model: {
                            value: _vm.driver.garage_other_address,
                            callback: function ($$v) {
                              _vm.$set(_vm.driver, "garage_other_address", $$v)
                            },
                            expression: "driver.garage_other_address",
                          },
                        },
                        [_vm._v(" Garagem em outro endereço? ")]
                      ),
                      _vm.driver.garage_other_address
                        ? _c("garage-address-edit", {
                            attrs: {
                              garage_other_address:
                                _vm.driver.garage_other_address || "",
                              street_name:
                                _vm.driver.garage_address.street_name || "",
                              street_number:
                                _vm.driver.garage_address.street_number || "",
                              street_details:
                                _vm.driver.garage_address.street_details || "",
                              neighborhood:
                                _vm.driver.garage_address.neighborhood || " ",
                              city: _vm.driver.garage_address.city || " ",
                              state: _vm.driver.garage_address.state || " ",
                              postal_code:
                                _vm.driver.garage_address.postal_code || " ",
                              block_ui: _vm.block_ui,
                            },
                            on: {
                              "update-garage-form-data":
                                this.updateGarageAddressModel,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                disabled: _vm.block_ui || !_vm.validFields,
                                variant: "success",
                              },
                              on: { click: _vm.updateDriver },
                            },
                            [_vm._v(" Salvar ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.block_ui,
                                to: "/drivers/profile/" + _vm.$route.params.id,
                                variant: "danger",
                              },
                            },
                            [_vm._v(" Cancelar ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }